<template>
  <div class="datetime-picker-wrapper">
    <div
      v-if="['date','datetime'].includes(type)"
      :class="{'date-picker': true, 'f-width': ['date'].includes(type)}"
    >
      <date-picker
        :value="dateStr"
        placement="bottom-end"
        :disabled="disabled"
        :size="size"
        :format="dateFormat"
        :clearable="false"
        @on-change="onChangeDate"
      ></date-picker>
    </div>
    <div
      v-if="['time','datetime'].includes(type)"
      :class="{'time-picker':true, 'f-width': ['time'].includes(type)}"
    >
      <time-picker
        :value="timeStr"
        placement="bottom-end"
        :disabled="disabled"
        :size="size"
        :format="timeFormat"
        :clearable="false"
        @on-change="onChangeTime"
      ></time-picker>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DateTimePicker',
  model: {
    prop: 'datetime',
    event: 'datetimechange'
  },
  props: {
    datetime: {
      type: [String, Date],
      default: '',
      required: true
    },
    format: {
      type: String,
      default: 'yyyy-MM-dd HH:mm:ss',
      required: false
    },
    type: {
      type: String,
      default: 'datetime',
      required: false,
      validator: (value) => {
        return ['date', 'time', 'datetime'].includes(value);
      }
    },
    size: {
      type: String,
      default: 'default',
      required: false,
      validator: (value) => {
        return ['small', 'large', 'default'].includes(value);
      }
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      datetimestamp: 0,
      dateStr: '',
      timeStr: '',
      dateFormat: 'yyyy-MM-dd',
      timeFormat: 'HH:mm:ss'
    };
  },
  computed: {
    datetimeStr() {
      if (!this.dateStr) this.dateStr = new Date().format(this.dateFormat);
      if (this.dateStr && !this.timeStr) this.timeStr = new Date().format(this.timeFormat);
      return `${this.dateStr || ''} ${this.timeStr || ''}`.trim();
    }
  },
  watch: {
    datetime: {
      immediate: true,
      handler: function (val) {
        if (!val) return;
        if (val instanceof Date) this.datetimestamp = val.getTime();
        else this.datetimestamp = new Date(val.trim()).getTime();
      }
    },
    format: {
      immediate: true,
      handler: function (val) {
        let [datef, timef] = val.split(' ');
        this.dateFormat = datef.trim();
        this.timeFormat = (timef || '').trim();
      }
    },
    datetimestamp: {
      immediate: true,
      handler: function (val) {
        if (!val) return;
        this.splitDateTime(val);
      }
    }
  },
  methods: {
    onChangeDate(value) {
      this.dateStr = value.trim();
      this.mergeDateTime();
    },
    onChangeTime(value) {
      this.timeStr = value.trim();
      this.mergeDateTime();
    },
    mergeDateTime() {
      if (this.datetimeStr.trim()) {
        let datetime = new Date(this.datetimeStr);
        this.datetimestamp = datetime.getTime();
        this.$emit('datetimechange', this.datetime instanceof Date ? datetime : this.datetimeStr);
      } else {
        this.$emit('datetimechange', '');
      }
    },
    splitDateTime(timestamp) {
      let datetime = new Date(timestamp);
      this.dateStr = datetime.format(this.dateFormat);
      this.timeStr = datetime.format(this.timeFormat);
    }
  }
}
</script>

<style lang="scss">
.datetime-picker-wrapper {
  width: 100%;
  height: 100%;
  display: inline-flex;

  .date-picker {
    width: calc(100% - 78px);
    padding-right: 5px;
  }
  .time-picker {
    width: 78px;
  }
  input {
    padding-right: 15px !important;
  }
  .ivu-input-icon {
    width: 24px !important;
  }

  .f-width {
    width: 100%;
    padding-right: 0;
    .ivu-date-picker {
      width: 100%;
    }
  }
}
</style>

