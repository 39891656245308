import { appConfig } from '@/config/appConfig';
import { createHttp } from '@/api/HttpHelper';


const axios = createHttp(appConfig.base2GUrl);


const get = (action, options) => axios.get(`excelExport/${action}.do`, options);


export const exportExcel = params => get('exportExcel', { responseType: 'blob', params }).then(res => URL.createObjectURL(res));


/**
 * 车辆授权数据导出
 * @param params.expireTime 
 * @param params.groupId 
 * @param params.vehicleId 
 * @param params.isState 
 * @param params.vehicleState 
 * @param params.offlineTime 
 * @param params.activationTmeStart 
 * @param params.activationTmeEnd 
 * @param params.installDateStart 
 * @param params.installDateEnd 
 * @param params.flag 
 * @param params.days 
 */
export const authorizeVehicle2Excel = params => exportExcel({ ...params, type: 0 });

/**
 * 车辆授权记录数据导出
 * @param {*} params.vehicleId  
 * @param {*} params.startTime  
 * @param {*} params.endTime  
 */
export const authorizeVehicleRecord2Excel = params => exportExcel({ ...params, type: 1 });

/**
 * 授权币划拨记录数据导出
 * @param {*} params.startTime  
 * @param {*} params.endTime  
 * @param {*} params.userId  
 */
export const authorizeCoinRecord2Excel = params => exportExcel({ ...params, type: 2 });

/**
 * 驾驶员信息导出
 * @param {*} groupId 
 */
export const driver2Excel = groupId => exportExcel({ groupId, type: 4 });

/**
 * 导出载重计量报表
 * @param {*} params.flag 0：车组；1：车辆
 * @param {*} params.vehicleId 车辆ID
 * @param {*} params.groupId 车组ID
 * @param {*} params.startTime 开始时间
 * @param {*} params.endTime 结束时间
 */
export const loadMeasure2Excel = params => exportExcel({ ...params, type: 10 });

/**
 * 导出车辆报警率
 * @param {*} params.days 0：导出每天的报警率，1：每月的报警率
 * @param {*} params.flag 0：导出车辆的报警率，1：车组的报警率，2：车组的车辆详细报警率
 */
export const vehicleAlarm2Excel = params => exportExcel({ ...params, type: 8 });

/**
 * 导出车组报警率
 * @param {*} params.days 0：导出每天的报警率，1：每月的报警率
 * @param {*} params.flag 0：导出车辆的报警率，1：车组的报警率，2：车组的车辆详细报警率
 */
export const groupAlarm2Excel = params => exportExcel({ ...params, type: 9 });

/**
 * 导出载重计量明细报表
 * @param {*} params.vehicleId 
 * @param {*} params.startTime 
 * @param {*} params.endTime 
 */
export const loadMeasureDeail2Excel = params => exportExcel({ ...params, type: 11 });

/**
 * 导出计价数据
 * @param {*} params.flag 0：车组；1：车辆
 * @param {*} params.groupId  车组ID
 * @param {*} params.vehicleId 车辆ID
 * @param {*} params.startTime 开始时间
 * @param {*} params.endTime 结束时间
 */
export const taximeter2Excel = params => exportExcel({ ...params, type: 12 });

/**
 * 导出计价明细数据
 * @param {*} params.groupId  车组ID
 * @param {*} params.vehicleId 车辆ID
 * @param {*} params.startTime 开始时间
 * @param {*} params.endTime 结束时间
 */
export const taximeterDetail2Excel = params => exportExcel({ ...params, type: 13 });


export const exportExcelTwo = params => get('exportExcel', { responseType: 'blob', params });

/**
 * 车辆评分导出
 * @param {*} params.flag 0：车组；1：车辆
 * @param {*} params.groupId 车组ID
 * @param {*} params.vehicleId 车辆ID
 * @param {*} params.startTime 开始时间
 * @param {*} params.endTime 结束时间
 */
export const vehicleScoring2Excel = params => exportExcelTwo({ ...params, type: 14 });

/**
 * 驾驶员评分导出
 * @param {*} params.flag 0：车组；1：从业资格证
 * @param {*} params.groupId 车组ID
 * @param {*} params.certificate 从业资格证
 * @param {*} params.startTime 开始时间
 * @param {*} params.endTime 结束时间
 */
export const driverScoring2Excel = params => exportExcelTwo({ ...params, type: 15 });


/**
 * 导出报表
 */
export const exportExcels = (val) => { return axios.get('excelExport/exportExcel.do', { responseType: 'blob', params: val }); };

/**
 * 根据车组车辆Id导出最后位置点数据
 * @param {*} params.groupId 车组Id
 * @param {*} params.vehicleId 车辆Id 查询车组，车辆Id为0
 * @param {*} params.posStatus  定位状态（全部=2/定位=1/不定位=0）
 * @param {*} params.vehicleStatus 车辆状态（全部=2/在线=1/离线=0）
 */
export const lastPos2Excel = ({ groupId, vehicleId, posStatus, vehicleStatus }) =>
  exportExcel({ params: { groupId, vehicleId, posStatus, vehicleStatus, flag: ~~!!vehicleId, type: 23 } });
