import { appConfig } from '@/config/appConfig';
import { createHttp } from '@/api/HttpHelper';

const axios = createHttp(appConfig.base2GUrl);
const axios2 = createHttp(appConfig.base2GUrl2);

const get = (action, options) => axios.get(`driver/${ action }.do`, options);
const post = (action, data, options) => axios.post(`driver/${ action }.do`, data, {
   headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
   ...options
});


/**
 * 验证身份证
 * @param {*} IDNumber 身份证
 */
export const checkIDNumber = (driverId, IDNumber) => get('checkIDNumber', { params: { driverId, IDNumber } });


/**
 * 验证从业资格证
 * @param {*} certificate 从业资格证
 */
export const certificate = (driverId, certificate) => get('certificate', { params: { driverId, certificate } });


/**
 * 新增驾驶员信息
 * @param {*} obj 驾驶员信息对象
 */
export const createDriver = (obj) => post('createDriver', `driver=${ JSON.stringify(obj) }`);

/**
 * 修改驾驶员信息
 * @param {*} obj 驾驶员信息
 */
export const updateDriver = (obj) => post('updateDriver', `driver=${ JSON.stringify(obj) }`);

/**
 * 删除驾驶员信息
 * @param {*} driverId 驾驶员ID
 */
export const deleteDriver = driverId => get('deleteDriver', { params: { driverId } });

/**
 * 上传驾驶员照片
 * @param {*} IDNumber 身份证
 */
export const uploadPhotos = (certificate, file, type = 0) => {
   let data = new FormData();
   // data.append('file',[file])
   data.append('file', file);
   data.append('certificate', certificate);
   data.append('fileName', `${ certificate }_${ type }`);
   return post('uploadPhotos', data, { headers: { 'Content-Type': 'multipart/form-data' } });
};

/**
 * 删除驾驶员照片
 * @param {*} path 照片路径
 */
export const detelePhotos = (certificate, type = 0) => get('detelePhotos', { params: { certificate, fileName: `${ certificate }_${ type }` } });

/**
 * 查看照片
 * @param {*} path 照片路径
 */
export const findDriverPhotos = (certificate, type = 0) => get('findDriverPhotos', { responseType: 'blob', params: { certificate, fileName: `${ certificate }_${ type }` } })
   .then(res => {
      if (res.size) return URL.createObjectURL(res);
   });

/**
 * 根据驾驶员ID查询驾驶员
 * @param {*} driverId 驾驶员ID
 */
export const findDriver = driverId => get('findDriver', { params: { driverId } });

/**
 * 根据车组获取驾驶员信息列表
 * @param {*} params.groupId 车组
 * @param {*} params.pageNumber 第几页
 * @param {*} params.pageSize 每页大小
 */
export const findDriverByGroupId = params => get('findDriverByGroupId', { params: { ...params } });


/**
 * 根据姓名、身份证搜索驾驶员
 * @param {*} args 驾驶员姓名、身份证
 */
export const searchDriver = args => get('searchDriver', { params: { args } });


/**
 * 根据certificate查询驾驶员信息
 * @param {*} params.certificate 驾驶员从业资格证
 * @param {*} params.startTime 开始时间
 * @param {*} params.endTime  结束时间
 */
export const findDriverByCertificate = params => get('findDriverByCertificate', { params: { ...params } });

/**
 * 根据vehicleId查询车辆信息
 * @param {*} params.vehicleId 车辆ID
 * @param {*} params.startTime  开始时间
 * @param {*} params.endTime   结束时间
 */

export const findVehicleInfo = params => get('findVehicleInfo', { params: { ...params } });

/**
 * 查询驾驶员或车辆评分
 * @param {*} params.flag  0=驾驶员,1=设备名称
 * @param {*} params.startTime  开始时间
 * @param {*} params.endTime    结束时间
 * @param {*} params.cvId  驾驶员从业资格证或车辆对应的ID值  certificateVehicleId
 */
export const findGraded = params => get('findGraded', { params: { ...params } });


/**
 * 查询驾驶员报警分布明细 和 评价需要的数据
 * @param {*} params.flag  0=驾驶员,1=设备名称
 * @param {*} params.startTime  开始时间
 * @param {*} params.endTime   结束时间
 * @param {*} params.cvId  驾驶员从业资格证或车辆对应的ID值  certificateVehicleId
 */
export const findAlarmDetail = params => get('findAlarmDetail', { params: { ...params } });


/**
 * 驾驶行为指数明细
 * @param {*} params.flag 0=驾驶员,1=设备名称
 * @param {*} params.startTime  开始时间
 * @param {*} params.endTime   结束时间
 * @param {*} params.cvId  驾驶员从业资格证或车辆对应的ID值  certificateVehicleId
 */
export const adasIndexDetail = params => get('adasIndexDetail', { params: { ...params } });


/**
 * 车辆可以支持按车组查询时段范围内的车辆的评分数据，并且按照分数进行排序展示
 * @param {*} params.flag 0=车组,1=车辆
 * @param {*} params.groupId 车组
 * @param {*} params.vehicleId 车辆ID
 * @param {*} params.startTime 开始时间
 * @param {*} params.endTime 结束时间
 * @param {*} params.pageSize 每页大小
 * @param {*} params.pageNumber 当前页
 */
// export const findVehicleRatingInfo = params => get('findVehicleRatingInfo', { params: {...params } })
export const findVehicleRatingInfo = (params) => axios2.get('activeDefense/findVehicleRatingInfo.do', { params });


/**
 * 车组驾驶行为统计-车组评分明细
 * @param {*} params.flag 0:车组，1：从业资格证
 * @param {*} params.alarmTypes 报警类型
 * @param {*} params.groupId 车组ID
 * @param {*} params.vehicleId 车辆ID
 * @param {*} params.startTime 开始时间
 * @param {*} params.endTime 结束时间
 * @param {*} params.pageSize 每页大小
 * @param {*} params.pageNumber 当前页
 */
export const findDriverRatingInfo = params => get('findDriverRatingInfo', { params: { ...params } });

/**
 * 车组评分明细中的明细
 * @param {*} params.alarmTypes 报警类型
 * @param {*} params.vehicleId 车辆ID
 * @param {*} params.startTime 开始时间
 * @param {*} params.endTime 结束时间
 */
export const getRatingInfoDetail = params => axios2.get('activeDefense/getRatingInfoDetail', { params: { ...params } });

/**
 * 车组评分明细中的明细导出
 * @param {*} params.alarmTypes 报警类型
 * @param {*} params.vehicleId 车辆ID
 * @param {*} params.startTime 开始时间
 * @param {*} params.endTime 结束时间
 */
export const exportRatingInfoDetail = params => axios2.get('activeDefense/getRatingInfoDetail', { responseType: 'blob', params: { ...params } });

/**
 * 导入驾驶员信息
 * @param {FormData} params
 */
export const importDriver = (params) => {
   return axios.post("driver/importDriver.do", params, {
      headers: {
         "Content-Type": "multipart/form-data",
      },
   });
};