<template>
  <div class="SiteInfoModal">
    <el-dialog
      title="批量导入信息"
      :visible="modal"
      width="80%"
      @close="onModalChange"
      append-to-body
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      destroy-on-close
    >
      <div slot="title" class="header-title">
        <span>批量导入信息</span>
      </div>
      <div class="selectfile-content">
        <div class="upload-input">
          <el-upload
            ref="upload"
            class="avatar-uploader"
            action="http://120.78.203.76:8086/ZtSite/importztsite.do"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="handleUpload"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>

          <!-- <Upload
            :before-upload="handleUpload"
            ref="upload"
            action="http://120.78.203.76:8086/ZtSite/importztsite.do"
          >
            <el-button type="primary" :disabled="loadingStatus">
              <i class="el-icon-upload el-icon--right"></i>请选择文件
            </el-button>
          </Upload>-->
          <div v-if="excelFile !== null">{{ excelFile.name }}</div>
        </div>
        <div class="upload-notice">
          <el-card shadow="never" style="border: none">
            <p>小技巧</p>
            <p>
              首次使用,请点击下载
              <a target="_blank" href="/resource/驾驶员信息.xlsx">最新导入模板</a>!一次批量提交的信息不能超过 1000条，如果超过限制将提交失败！
              如果系统提示您有未完成的任务，可能是上次批量导入未完成或此时其他电脑正在用您的账户进行批量导入。
              请避免您的账号,同一时间,使用不同电脑进行批量导入！！
            </p>
          </el-card>
        </div>
        <div v-if="excelFile !== null" class="submit-btn">
          <el-button
            type="primary"
            @click="uploadFile"
            :loading="loadingStatus"
          >{{ loadingStatus ? "上传中..." : "点击上传" }}</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { importDriver } from "@/api/driver-api";

export default {
  model: {
    prop: "modal",
    event: "modal-change",
  },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imageUrl: '',
      excelFile: null,
      loadingStatus: false,
    };
  },
  watch: {
    modal(val) {
      if (!val) {
        this.excelFile = null;
      }
    },
  },
  methods: {
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    // colse() {
    //   this.stepActive = 0;
    //   this.onModalChange(false, "on-ok");
    // },
    onModalChange(modal, eventName = "on-cancel") {
      this.$emit("modal-change", modal);
      this.$emit(eventName);
    },

    handleUpload: function (excelFile) {
      this.excelFile = excelFile;
      return false;
    },

    uploadFile() {
      this.loadingStatus = true;
      let formData = new FormData();
      formData.append("file", this.excelFile);
      importDriver(formData).then((res) => {
        this.$message.info(res.msg);
        this.loadingStatus = false;
        if (res.flag === 1) {
          this.excelFile = null;
          this.onModalChange();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.selectfile-content {
  .upload-input {
    height: 120px;
    text-align: center;
    margin-top: 50px;
    .upload-action {
      width: 300px;
    }
  }
}
.upload-notice {
  width: 60%;
  margin: 50px auto;
  .color {
    color: red;
  }
}
.submit-btn {
  text-align: right;
  margin-right: 15%;
}
.upload-input:last-child {
  display: none;
}
.header-title {
  background: #212d3e;
  color: #fff;
  padding: 15px 20px;
  line-height: normal;
}
::v-deep .el-dialog__header {
  padding: 0;
}
</style>
