<template>
  <div v-if="modal"
    class="modal-driver-info-wrapper">
    <el-dialog :visible="modal"
      width="60%"
      class="modal-driver-info-vertical-center"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body
      destroy-on-close
      @close="onModalChange">
      <div slot="title"
        class="header-title">
        <span>编辑驾驶员资料</span>
      </div>
      <!-- <div slot='header'
        style='position: relative;'>
        <span>编辑驾驶员资料</span>
        <span style='position: absolute;right: 0px;top: -6px;cursor: pointer;'
          @click='onModalChange(false)'>
          <icon size='32'
            type="ios-close" />
        </span>
      </div>-->
      <div class="driver-info-content">
        <div class="content">
          <div class="pane">
            <span class="title">基本信息</span>
            <div class="pane-content first-content">
              <ul>
                <li>
                  <div class="item item-cell2">
                    <span class="first-item required">司机姓名</span>
                    <div class="last-item">
                      <el-input v-model.trim="driverObj.driverName"
                        :disabled="isTmpOrMonitorUser"
                        :maxlength="20" />
                    </div>
                  </div>
                  <div class="item item-cell2">
                    <span class="first-item">联系电话</span>
                    <div class="last-item">
                      <el-input v-model.trim="driverObj.phone"
                        :disabled="isTmpOrMonitorUser"
                        :maxlength="11" />
                    </div>
                  </div>
                </li>
                <li>
                  <div class="item item-cell2">
                    <span class="first-item">司机性别</span>
                    <div class="last-item">
                      <el-select v-model="driverObj.gender"
                        :disabled="isTmpOrMonitorUser">
                        <el-option v-for="gender in genders"
                          :key="gender"
                          :value="gender"
                          :label="gender"></el-option>
                      </el-select>
                    </div>
                  </div>
                  <div class="item item-cell2">
                    <span class="first-item required">身份证号</span>
                    <div class="last-item">
                      <el-input v-model.trim="driverObj.IDNumber"
                        :disabled="isTmpOrMonitorUser"
                        :maxlength="18"
                        @blur="onValidateIDNumber" />
                    </div>
                  </div>
                </li>
                <li>
                  <div class="item item-cell2">
                    <span class="first-item required">所属车组</span>
                    <div class="last-item">
                      <el-input v-model="groupStr">
                        <SelectGroupPopover @nodeClick="onClickCarGroup"
                          slot="append"></SelectGroupPopover>
                      </el-input>
                    </div>
                  </div>
                  <div class="item item-cell2">
                    <span class="first-item">出生日期</span>
                    <div class="last-item">
                      <el-date-picker v-model="driverObj.birthdate"
                        type="date"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        :disabled="isTmpOrMonitorUser"></el-date-picker>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="item item-cell2">
                    <span class="first-item">押运员</span>
                    <div class="last-item">
                      <el-input v-model.trim="driverObj.supercargoName"
                        :disabled="isTmpOrMonitorUser"
                        :maxlength="20" />
                    </div>
                  </div>
                  <div class="item item-cell2">
                    <span class="first-item">准驾车型</span>
                    <div class="last-item">
                      <el-input v-model.trim="driverObj.quasiDrivingType"
                        :disabled="isTmpOrMonitorUser"
                        :maxlength="20" />
                    </div>
                  </div>
                </li>
                <li>
                  <div class="item item-cell2">
                    <span class="first-item">年审日期</span>
                    <div class="last-item service-expires">
                      <el-date-picker v-model="driverObj.nextReturnDue"
                        type="date"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        :disabled="isTmpOrMonitorUser"></el-date-picker>
                    </div>
                  </div>
                  <div class="item item-cell2">
                    <span class="first-item">IC卡号</span>
                    <div class="last-item">
                      <el-input v-model.trim="driverObj.icCard"
                        :disabled="isTmpOrMonitorUser"
                        :maxlength="20" />
                    </div>
                  </div>
                </li>
                <li>
                  <div class="item item-cell2">
                    <span class="first-item">驾驶证</span>
                    <div class="last-item">
                      <el-input v-model.trim="driverObj.driverCard"
                        :disabled="isTmpOrMonitorUser"
                        :maxlength="20" />
                    </div>
                  </div>
                  <div class="item item-cell2">
                    <span class="first-item">所属区域</span>
                    <div class="last-item">
                      <el-input v-model.trim="driverObj.area"
                        :disabled="isTmpOrMonitorUser" />
                    </div>
                  </div>
                </li>
                <li>
                  <div class="item item-cell2">
                    <span class="first-item">联系地址</span>
                    <div class="last-item">
                      <el-input v-model.trim="driverObj.address"
                        :disabled="isTmpOrMonitorUser" />
                    </div>
                  </div>
                  <div class="item item-cell2">
                    <span class="first-item">籍贯</span>
                    <div class="last-item">
                      <el-input v-model.trim="driverObj.nativeplace"
                        :disabled="isTmpOrMonitorUser" />
                    </div>
                  </div>
                </li>
              </ul>
              <div>
                <SelectAvatar :src="driverPhotos[0].src"
                  :extra="driverPhotos[0].type"
                  @on-change="onSelectPhoto"
                  placeholder="司机照片1"></SelectAvatar>
                <p>建议使用2寸小于200KB的照片</p>
              </div>
            </div>
          </div>
          <div class="pane">
            <span class="title">从业资格证信息</span>
            <div class="pane-content">
              <ul>
                <li>
                  <div class="item item-cell3">
                    <span class="first-item required">从业资格证</span>
                    <div class="last-item">
                      <el-input v-model.trim="driverObj.certificate"
                        :maxlength="30"
                        @blur="onValidateCertificate"
                        @keyup.native="driverObj.certificate = driverObj.certificate.replace(/[^\a-\z\A-\Z0-9]/g,'')" />
                    </div>
                  </div>
                  <div class="item item-cell3">
                    <span class="first-item">发证机构</span>
                    <div class="last-item">
                      <el-input v-model.trim="driverObj.certifyingAuthority"
                        :disabled="isTmpOrMonitorUser"
                        :maxlength="20" />
                    </div>
                  </div>
                  <div class="item item-cell3">
                    <span class="first-item">发证时间</span>
                    <div class="last-item datetime-input-item">
                      <el-date-picker v-model="driverObj.issueDate"
                        type="date"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        :disabled="isTmpOrMonitorUser"></el-date-picker>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="item item-cell3">
                    <span class="first-item">监管机构</span>
                    <div class="last-item">
                      <el-input v-model.trim="driverObj.regulator"
                        :disabled="isTmpOrMonitorUser"
                        :maxlength="20" />
                    </div>
                  </div>
                  <div class="item item-cell3">
                    <span class="first-item">监督电话</span>
                    <div class="last-item">
                      <el-input v-model.trim="driverObj.regulatoryPhone"
                        :disabled="isTmpOrMonitorUser"
                        :maxlength="11" />
                    </div>
                  </div>
                  <div class="item item-cell3">
                    <span class="first-item">过期时间</span>
                    <div class="last-item datetime-input-item">
                      <el-date-picker v-model="driverObj.expirationTime"
                        type="date"
                        value-format="yyyy-MM-dd 23 :59:59"
                        :disabled="isTmpOrMonitorUser"></el-date-picker>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="pane">
            <span class="title">证件图片</span>
            <div class="pane-content paperwork-content">
              <SelectAvatar :src="driverPhotos['f'].src"
                :extra="driverPhotos['f'].type"
                placeholder="身份证正面"
                @on-change="onSelectPhoto"></SelectAvatar>
              <SelectAvatar :src="driverPhotos['b'].src"
                :extra="driverPhotos['b'].type"
                placeholder="身份证背面"
                @on-change="onSelectPhoto"></SelectAvatar>
              <SelectAvatar :src="driverPhotos['c'].src"
                :extra="driverPhotos['c'].type"
                placeholder="驾驶证"
                @on-change="onSelectPhoto"></SelectAvatar>
              <SelectAvatar :src="driverPhotos[1].src"
                :extra="driverPhotos[1].type"
                placeholder="司机照片2"
                @on-change="onSelectPhoto"></SelectAvatar>
              <SelectAvatar :src="driverPhotos[2].src"
                :extra="driverPhotos[2].type"
                placeholder="司机照片3"
                @on-change="onSelectPhoto"></SelectAvatar>
              <SelectAvatar :src="driverPhotos[3].src"
                :extra="driverPhotos[3].type"
                placeholder="司机照片4"
                @on-change="onSelectPhoto"></SelectAvatar>
              <SelectAvatar :src="driverPhotos[4].src"
                :extra="driverPhotos[4].type"
                placeholder="司机照片5"
                @on-change="onSelectPhoto"></SelectAvatar>
              <SelectAvatar :src="driverPhotos[5].src"
                :extra="driverPhotos[5].type"
                placeholder="司机照片6"
                @on-change="onSelectPhoto"></SelectAvatar>
            </div>
          </div>
          <div class="pane">
            <span class="title">备注信息</span>
            <div class="pane-content">
              <div class="item item-cell1">
                <div class="first-item"></div>
                <div class="last-item">
                  <el-input v-model.trim="driverObj.remark"
                    :disabled="isTmpOrMonitorUser"
                    type="textarea"
                    :rows="4"
                    :maxlength="200"
                    placeholder="请输入备注" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="bottom">
          <el-button type="primary"
            size="default"
            @click="onSaveData">保存</el-button>
          <el-button size="default"
            @click="onModalChange(false)">取消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>


import DateTimePicker from '@/components/DateTimePicker';
import SelectGroupPopover from '@/components/SelectGroupPopover';
import SelectAvatar from '@/components/SelectAvatar';
import { isCardNo, phoneFun } from "@/utils/index";

import {
  createDriver,
  updateDriver,
  deleteDriver,
  findDriver,
  checkIDNumber,
  certificate,
  uploadPhotos,
  findDriverPhotos
} from '@/api/driver-api';

// 默认图片
const defaultImg = require('../assets/images/avatar.png');

export default {
  name: 'ModalDriverInfo',
  components: {
    DateTimePicker,
    SelectAvatar,
    SelectGroupPopover
  },
  model: {
    prop: 'modal',
    event: 'change'
  },
  props: {
    modal: {
      type: Boolean,
      default: false
    },
    driverId: {
      type: Number,
      default: 0
    },
    groupData: {
      type: Object,
      default: ()=>({groupId:'',groupName:''})
    }
  },
  data() {
    return {
      groupStr: "",
      carGroupDropdownVisible: false,
      driverObj: this.initDriver(),
      genders: ['男', '女'],
      driverPhotos: this.initDriverPhotos()
    };
  },
  computed: {
    // ...mapState(['user']),
    // ...mapGetters(['isTmpUser', 'isMonitorUser']),
    isTmpOrMonitorUser() {
      return false;
      return this.isTmpUser || this.isMonitorUser;
    },
  },
  watch: {
    modal: {
      handler: function (val) {
        if (val) {
          this.groupData.groupName ? (this.groupStr = this.groupData.groupName, this.driverObj.groupName = this.groupData.groupName, this.driverObj.groupId = this.groupData.groupId) : ''
          if (!this.driverId) return;
          findDriver(this.driverId)
            .then(res => {
              if (res.flag && res.obj) {
                this.driverObj = { ...res.obj };
                this.groupStr = this.driverObj.groupName;
                this.getAllPhotos(this.driverObj.certificate);
              } else if (res.msg) {
                this.$message.watch(res.msg);
              }
            });
        } else {
          this.driverObj = this.initDriver();
          this.driverPhotos = this.initDriverPhotos();
        }
      }
    }
  },
  methods: {
    onModalChange(modal, eventName = 'on-cancel', ...options) {
      this.$emit('change', modal);
      this.$emit(eventName, ...options);
    },
    onValidateIDNumber() {
      if (this.driverObj.IDNumber.length !== 18) return;
      checkIDNumber(this.driverObj.driverId, this.driverObj.IDNumber)
        // .then(res => {
        //   if (!res.flag && res.msg) this.$message.warning(res.msg);
        // });
    },
    onValidateCertificate() {
      if (!this.driverObj.certificate) return;
      certificate(this.driverObj.driverId, this.driverObj.certificate)
        // .then(res => {
        //   if (!res.flag && res.msg) this.$message.warning(res.msg);
        // });
    },
    async validateData() {
      let { driverName, IDNumber, phone, groupName, groupId, birthdate } = this.driverObj;

      if (!driverName.length) {
        this.$message.error('请输入驾驶员姓名');
        return false;
      }
      if (!isCardNo(IDNumber)) {
        this.$message.error('请输入正确的身份证号码');
        return false;
      }

      if (!(groupName.length && groupId > -1)) {
        this.$message.error('请选择有效车组');
        return false;
      }

      if (phone && !phoneFun(phone)) {
        this.$message.error('请输入正确的手机号');
        return false;
      }

      if (this.driverObj.certificate.length < 3) {
        this.$message.error("请输入有效的从业资格证");
        return false;
      }

      let res = await Promise.all([
        checkIDNumber(this.driverObj.driverId, IDNumber),
        certificate(this.driverObj.driverId, this.driverObj.certificate)]);
      res.forEach(p => p&&!p.flag && p.msg && this.$message.error(p.msg))
      return res.every(p => p ? p.flag : 1);
    },
    async onSaveData() {
      if (!(await this.validateData())) return;
      let action = [createDriver, updateDriver][~~!!this.driverObj.driverId];
      action({ ...this.driverObj })
        .then(res => {
          console.log(res);
          if (res.msg) this.$message.info(res.msg);
          if (res.flag) {
            this.uploadAllPhotos(this.driverObj.certificate);
            this.onModalChange(false, 'on-ok', { ...this.driverObj });
          }else {
            this.$message.error(res.msg)
          }
        });
      const { groupName,groupId} = this.driverObj;
      
      this.$emit("selectVGroup",groupName);
      this.$emit("getDriversByGroupId",groupId);
    },
    uploadAllPhotos(certificate) {
      for (let p in this.driverPhotos) {
        if (this.driverPhotos[p].file) uploadPhotos(certificate, this.driverPhotos[p].file, p);
      }
    },
    getAllPhotos(certificate) {
      this.initDriverPhotos();
      for (let p in this.driverPhotos) {
        findDriverPhotos(certificate, this.driverPhotos[p].type)
          .then(src => {
            if (src) {
              this.driverPhotos[p].src = src;
            }
          });
      }
    },
    onSelectPhoto(file, type) {
      this.driverPhotos[type].file = file;
    },
    onClickCarGroup(data) {
      this.driverObj.groupId = ~~data.groupId;
      this.driverObj.groupName = data.groupName;
      this.groupStr = data.groupName;
      this.carGroupDropdownVisible = false;
    },
    onFilterCarGroup(data) {
      this.driverObj.groupId = ~~data.groupId;
      this.driverObj.groupName = data.groupName;
    },
    initDriver() {
      return {
        driverId: 0,
        driverName: '', //驾驶员姓名
        icCard: '', //驾驶员IC卡号
        phone: '', //驾驶员电话
        driverCard: '', //驾驶员驾驶证
        gender: '男', //性别
        IDNumber: '', //身份证
        groupId: -1, //所属车组ID
        groupName: '', //所属车组
        birthdate: '', //出生日期
        supercargoName: '', //押运员姓名
        quasiDrivingType: '', //准驾车型
        nextReturnDue: '', //年审日期
        picture: '', //驾驶员照片
        area: "", //所属区域
        address: "", //联系地址
        nativeplace: "", //籍贯

        certificate: '', //从业资格证
        certifyingAuthority: '', //发证机构
        issueDate: '', //发证时间
        regulator: '', //监管机构
        regulatoryPhone: '', //监督电话
        expirationTime: '', //过期时间

        remark: '',
      };
    },
    initDriverPhotos() {
      let obj = {};
      let arr = [0, 1, 2, 3, 4, 5, 'f', 'b', 'c'];
      arr.forEach(p => obj[p] = { type: p, src: '', file: null });
      obj[0].src = defaultImg;
      return obj;
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-driver-info-wrapper {
  position: relative;
}

.modal-driver-info-vertical-center {
  display: flex;
  align-items: center;
  justify-content: center;

  .driver-info-content {
    height: calc(100vh - 265px);
    font-size: 14px;
    position: relative;
    overflow: auto;

    .content {
      overflow: auto;
      height: calc(100% - 60px);
    }

    .bottom {
      position: absolute;
      bottom: 0;
      height: 60px;
      width: 100%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-top: 1px solid #e8eaec;

      & > :first-child {
        margin-right: 5px;
        width: 80px;
      }
      & > :last-child {
        margin-left: 5px;
        width: 80px;
      }
    }

    .pane {
      margin: 10px;
      &:last-child {
        margin-bottom: 40px;
      }
    }

    .title {
      font-weight: bold;
      color: #2d8cf0;
    }
    .pane-content {
      margin: 10px;
      & > ul > li {
        display: flex;
        margin-bottom: 10px;
      }
    }
    .item {
      margin: 1px;
      display: flex;
      align-items: center;
      position: relative;
    }
    .item-cell1 {
      width: 100%;
    }
    .item-cell2 {
      width: 50%;
    }
    .item-cell3 {
      width: 33.33%;
    }

    .first-item {
      width: 105px;
      //   text-align: end;
      // white-space: nowrap;
      display: inline-flex;
      justify-content: flex-end;
      align-items: center;
    }
    .required::before {
      content: '*';
      color: red;
      font-weight: bold;
      margin-right: 1px;
      font-size: 20px;
    }

    .last-item {
      position: relative;
      width: calc(100% - 105px);
      flex-grow: 1;
      margin-left: 10px;
      // border: 1px solid red;
      height: 100%;
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;
    }
    .service-expires {
      .poptip-content {
        color: red;
        font-size: 18px;
      }
    }
    .car-group-dropdown {
      & > div:first-child {
        width: 100%;
      }
      .ivu-select-dropdown {
        max-height: 250px !important;
        overflow: auto !important;
      }
    }
    .datetime-input-item {
      & > div:first-child {
        width: 100%;
      }
    }
    .first-content {
      display: flex;
      & > ul:first-child {
        width: 66.66%;
      }
      & > div:last-child {
        width: 33.33%;
        height: 250px;
        padding: 0px 60px 38px;
        & > p {
          padding: 5px;
          color: red;
        }
      }
    }
    .paperwork-content {
      display: flex;
      flex-wrap: wrap;
      & > div {
        height: 140px;
        margin: 2px;
        width: calc((100% - 16px) / 4);
      }
    }
  }
}
.header-title {
  background: #212d3e;
  color: #fff;
  padding: 15px 20px;
  line-height: normal;
}
::v-deep .el-dialog__header {
  padding: 0;
}
</style>

<style lang="scss" scoped src="@/style/resetElDialogPadding.scss">
</style>
