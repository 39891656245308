<template>
  <el-container class="driver-manage-wrapper">
    <el-header class="car-content-header"
      height="32px">
      <el-button type="primary"
        @click="addDriver()">
        <i class="fa fa-car"
          aria-hidden="true"></i>添加驾驶员
      </el-button>
      <Export style="display: inline-block; margin-left: 15px"
        :disabled="!tableDataHas || !tableDataShow"
        title="驾驶员信息"
        exportUrl="excelExport/exportExcel.do"
        :params="{ groupId: selectedTreeData.groupId, type: 4 }"></Export>
      <el-button @click="onImportclick">批量导入</el-button>
    </el-header>
    <el-container class="car-content-body"
      style="padding-bottom:0">
      <!-- left -->
      <el-aside v-show="expandSide"
        class="mgt-side"
        width="238px">
        <SelectGroupTree :inputVehicle="false"
          :multipleProp="{ noGroup: true }"
          :inputDriver="true"
          style="position: absolute;width: 239px;"
          @on-select="selectVGroup"
          @dbClick="selectVGroup"
          @on-vehicle="selectVGroup" />

        <el-collapse v-model="activeNames"
          style="height: 96%;margin-top: 32px;">
          <el-collapse-item name="1">
            <template slot="title">
              <span style="font-size:16px;font-weight: 700;">
                <label>工程机械列表</label>
              </span>
            </template>
            <GroupTree style="height:73.5vh"
              :showCheckbox="false"
              :ellipsis="true"
              ref="groupTree"
              @checkChange="checkChange"
              @nodeClick="selectVGroup"
              :propGroupList="groupList"
              :propTreeLoading="treeLoading"
              :propEmptyText="emptyText" />
          </el-collapse-item>
        </el-collapse>
      </el-aside>
      <!-- middle -->
      <fold-button @click="expandSide = !expandSide"
        :expandSide="expandSide"></fold-button>
      <!-- right -->
      <el-main class="right">
        <!-- <Table
            size="small"
            :loading="tableLoading"
            :row-class-name="rowClassName"
            @on-selection-change="onSelectedDataChange"
            border
            :columns="columnsData"
            :data="driverList"
            ref="table"
        ></Table>-->
        <el-table ref="table"
          :row-class-name="rowClassName"
          highlight-current-row
          height="94%"
          v-loading="tableLoading"
          size="small"
          :data="driverList"
          class="el-table-style"
          :header-cell-style="{background:'#f5f5f5',color:'#262626','font-weight':'bold'}"
          :row-style="{height:'50px'}">
          <el-table-column type="index"
            width="50"
            label="序号"></el-table-column>
          <el-table-column v-for="(item, index) in columnsData"
            :key="index"
            :prop="item.key"
            :label="item.title"
            :width="item.width"></el-table-column>
          <el-table-column label="操作"
            min-width="180px"
            v-slot="{ row }"
            fixed="right">
            <!-- <a href="javascript:;" style="margin:0 10px" @click="onEditDriver(row.driverId)">编辑</a> -->
            <el-button type="text"
              @click="onEditDriver(row.driverId)"
              style="margin-right:10px">编辑</el-button>
            <el-popconfirm title="是否确定删除？"
              @confirm="onDelDriver(row.driverId, row.certificat,row)">
              <el-button type="text"
                slot="reference">删除</el-button>
            </el-popconfirm>
            <!-- <a href="javascript:;">删除</a> -->
          </el-table-column>
        </el-table>

        <el-pagination background
          :current-page="tablePage.pageCurrent"
          :page-size="tablePage.pageSize"
          layout="total, prev, pager, next, jumper"
          :total="tablePage.total"
          @current-change="changePage"
          style="text-align: center; margin: 10px 10px 0 10px"></el-pagination>
      </el-main>
    </el-container>
    <modal-driver-info v-model="driverInfoModal.modal"
      :groupData="groupData"
      :driverId="driverInfoModal.driverId"
      @on-ok="onSaveOkDriver"
      @on-cancel="onCancelDriver"></modal-driver-info>
    <import-Modal v-model="importModal"></import-Modal>
  </el-container>
</template>

<script>
import moment from 'dayjs';
import { mapState, mapActions, mapGetters } from "vuex";
import {
  findDriverByGroupId,
  deleteDriver,
  detelePhotos,
  searchDriver,
} from "@/api/driver-api";
import { driver2Excel } from "@/api/excelExport-api";
import FoldButton from "@/components/FoldButton";
import { saveAsFile } from '@/utils/saveAsFile';
import { loadGroups } from '@/api/getData.js';
import ModalDriverInfo from "@/components/ModalDriverInfo";
import Export from "@/components/Export";
import { hasPerms } from "@/utils/auth";
import importModal from "./ImportDriver";
import GroupTree from '@/components/GroupTree';
import SelectGroupTree from '@/components/GroupTree/SelectGroupTree';
export default {
  name: "DriverManagement",
  components: {
    ModalDriverInfo,
    Export,
    importModal,
    FoldButton, GroupTree,
    SelectGroupTree
  },
  data() {
    return {
      driverInfoModal: {
        modal: false,
        driverId: 0,
      },
      expandSide: true,
      searchValue: "", //搜索input
      searchDataList: [], //搜索弹窗列表
      searchSelectedList: [],
      selectedTreeData: {}, //选中数据
      visible: true, //搜索弹窗
      columnsData: [],
      driverList: [], //table数据
      tableLoading: false,
      tableSelectedData: null, //table单选
      tablePage: {
        //分页
        pageSizeOpts: [20, 50, 100],
        pageCurrent: 1,
        pageSize: 50,
        total: 0,
      },
      groupData: {
        groupId: '',
        groupName: ''
      },
      importModal: false,
      selectedData: {}, //选中数据
      activeNames: '1',

      //--- groupTree变量
      treeLoading: false,
      groupList: [],
      emptyText: '--',
      //---
    };
  },
  mounted() {
    //test
    // this.getDriversByGroupId(37204, 1)
    this.initColumns();
    this.loadGroupTree();
  },
  activated() {
    //this.driverList = []
  },
  beforeDestroy() { },
  computed: {
    ...mapState(['userInfo']),
    tableDataHas: function () {
      return this.tablePage.total > 0;
    },
    tableDataShow: function () {
      if ((this.selectedTreeData || {}).groupId && this.tablePage.total) {
        return true;
      }
      return false;
    },
    treeData() {
      return {
        //树形图参数
        treeId: "tree",
        parentIf: true,
        hoverBtnIf: false,
        checkIf: false,
      };
    },
  },
  watch: {
    driverList: function () {
      this.tableSelectedData = null;
    },
  },
  methods: {
    setEmptyText(text = '--') {
      this.emptyText = text;
    },
    async loadGroupTree() {
      const userId = this.userInfo.userId;
      this.groupList = [];
      this.treeLoading = true;
      this.setEmptyText();
      try {
        const result = await loadGroups(userId);
        this.setEmptyText(result?.msg);
        if (result.flag !== 1) return;
        if (!result.obj) return;
        let groupList = result.obj.filter(p => p.groupId !== 0 && p.parentId !== 0); //筛选掉监管车组
        this.groupList = groupList;
      } catch (err) {
        console.error(err);
      } finally {
        this.treeLoading = false;
      }
    },
    confirmList(data) {
      console.log(data);
    },
    checkChange(node, checked) {
      const { groupId, groupName } = node;
      this.groupData.groupId = groupId;
      this.groupData.groupName = groupName;
      this.getDriversByGroupId(groupId);
    },
    nodeClickTest(data) {
      console.log(data);
    },
    async searchValueData() {
      let searchValue = this.searchValue.trim();
      if (searchValue !== "") {
        let list = this.groupList
          .filter((item) =>
            item.groupName.toLowerCase().includes(searchValue.toLowerCase())
          )
          .map((p) => {
            return { ...p, type: 0 };
          });

        if (list.length < 12) {
          let result = await searchDriver(searchValue);
          if (result.flag) {
            list.push(
              ...result.rows.map((p) => {
                return { ...p, type: 1 };
              })
            );
          }
        }
        // this.searchDataList = list.slice(0, 12);
        this.searchDataList = list;
        this.searchSelectedList = this.searchDataList.map((p, i) => i === 0);
        this.visible = false;
      } else {
        this.visible = true;
        this.searchDataList = [];
      }
    },
    keyDown() {
      if (!this.visible) {
        let arrIndex = this.searchSelectedList.findIndex((p) => p);
        this.$set(this.searchSelectedList, arrIndex, false);
        if (arrIndex >= this.searchSelectedList.length - 1) {
          this.$set(this.searchSelectedList, 0, true);
        } else {
          this.$set(this.searchSelectedList, arrIndex + 1, true);
        }
      }
    },
    onImportclick() {
      this.importModal = true;
    },
    keyUp() {
      if (!this.visible) {
        let arrIndex = this.searchSelectedList.findIndex((p) => p);
        this.$set(this.searchSelectedList, arrIndex, false);
        if (arrIndex <= 0) {
          this.$set(
            this.searchSelectedList,
            this.searchSelectedList.length - 1,
            true
          );
        } else {
          this.$set(this.searchSelectedList, arrIndex - 1, true);
        }
      }
    },
    keyEnter() {
      let arrIndex = this.searchSelectedList.findIndex((p) => p);
      this.selectedSearchItem(this.searchDataList[arrIndex]);
    },
    onCancel() {
      this.visible = true;
    },
    selectedSearchItem(val) {
      let { groupName, driverName, groupId, driverId, type } = val;
      groupId = ~~groupId;
      this.selectedTreeData = { groupId };
      if (type) {
        this.searchValue = driverName;
        this.tableSelectedData = { driverId: ~~driverId };
      } else {
        this.searchValue = groupName;
      }
      this.visible = true;
      this.getDriversByGroupId(groupId, 1);
    },
    rowClassName(row, index) {
      if (row.driverId === (this.tableSelectedData || {}).driverId) {
        return "demo-table-info-row";
      }
      return "";
    },
    selectVGroup(manageWay) {
      let { groupId, groupName } = manageWay;
      this.groupData.groupId = groupId;
      this.groupData.groupName = groupName;
      this.getDriversByGroupId(groupId, 1);
      this.tablePage.pageCurrent = 1;
      this.selectedTreeData = { groupId };
    },
    addDriver() {
      this.driverInfoModal.driverId = 0;
      this.driverInfoModal.modal = true;
    },
    getDriversByGroupId(groupId, pageNumber = 1) {
      if (groupId) {
        this.tableLoading = true;
        this.driverList = [];
        this.tablePage.pageCurrent = 1;
        this.tablePage.total = 0;
        findDriverByGroupId({
          groupId,
          pageNumber,
          pageSize: this.tablePage.pageSize,
        }).then((res) => {
          this.tableLoading = false;
          if (res.flag && res.rows) {
            this.driverList = [...res.rows].reverse();
            this.tablePage.total = res.total;
          } else if (res.msg) {
            this.$message({
              type: 'warning',
              message: res.msg
            });
          }
        });
      }
    },
    onSaveOkDriver(driver) {
      let { groupId, groupName } = driver;
      this.$refs.groupTree.$children[0].currentKey = groupId;
      this.getDriversByGroupId(groupId, 1);
      this.tablePage.pageCurrent = 1;
      this.selectedTreeData = { groupId };
      if ((this.selectedTreeData || {}).groupId) {
        this.getDriversByGroupId(this.selectedTreeData.groupId, 1);
      }
    },
    onCancelDriver() {
      this.driverInfoModal.driverId = 0;
    },
    onSelectedDataChange(data) {
      this.tableSelectedData = data;
    },
    onEditDriver(driverId) {
      if (driverId) {
        this.driverInfoModal.driverId = driverId;
        this.driverInfoModal.modal = true;
      }
    },
    onDelDriver(driverId, certificate, row) {
      if (driverId) {
        this.delAllPhotos(certificate);
        deleteDriver(driverId).then((res) => {
          if (res.flag && (this.selectedTreeData || {}).groupId) {
            this.getDriversByGroupId(this.selectedTreeData.groupId, 1);
          }
          if (row) {
            this.selectVGroup(row);
          }
          if (res.msg) this.$message({
            type: 'warning',
            message: res.msg
          });
        });

      }
    },
    delAllPhotos(certificate) {
      let arr = [0, 1, 2, 3, 4, 5, "f", "b", "c"];
      arr.forEach((p) => detelePhotos(certificate, p));
    },
    pageSizeChange(pageSize) {
      this.tablePage.pageSize = pageSize;
      this.changePage(1);
    },
    changePage(current) {
      this.tablePage.pageCurrent = current;
      if ((this.selectedTreeData || {}).groupId)
        this.getDriversByGroupId(this.selectedTreeData.groupId, current);
    },
    exportDataSearch() {
      if ((this.selectedTreeData || {}).groupId && this.tablePage.total) {
        driver2Excel(this.selectedTreeData.groupId).then((res) => {
          saveAsFile(res, `驾驶员-${ moment(new Date()).format("yyyyMMddHHmmss") }.xls`);
        });
      }
    },
    initColumns() {
      this.columnsData = [
        // {
        //   title: '操作',
        //   width: 100,
        //   render: (h, params) => {
        //     let driverId = params.row.driverId;
        //     let certificate = params.row.certificate;
        //     return (<div>
        //       <a onClick={() => this.onEditDriver(driverId)}>编辑</a>&nbsp;
        //       <poptip
        //         confirm
        //         transfer
        //         title="您确认删除吗?"
        //         onOn-ok={() => this.onDelDriver(driverId, certificate)}>
        //         <a>删除</a>
        //       </poptip>
        //     </div >);
        //   }
        // },

        {
          title: "司机姓名",
          key: "driverName",
          width: 120,
        },
        {
          title: "联系电话",
          key: "phone",
          width: 160,
        },
        {
          title: "性别",
          key: "gender",
          width: 120,
        },
        {
          title: "身份证",
          key: "IDNumber",
          width: 200,
        },
        {
          title: "出生日期",
          key: "birthdate",
          width: 200,
          render: (h, params) => {
            let birthdate = params.row.birthdate;
            return (
              <p>{birthdate ? moment(new Date(birthdate)).format("yyyy-MM-dd") : ""}</p>
            );
          },
        },
        // {
        //   title: "押运员",
        //   key: "supercargoName",
        //   width: 120,
        // },
        {
          title: "准驾车型",
          key: "quasiDrivingType",
          width: 120,
        },
        {
          title: "年审日期",
          key: "nextReturnDue",
          width: 200,
          render: (h, params) => {
            let nextReturnDue = params.row.nextReturnDue;
            return (
              <p>
                {nextReturnDue
                  ? moment(new Date(nextReturnDue)).format("yyyy-MM-dd")
                  : ""}
              </p>
            );
          },
        },
        // {
        //   title: "IC卡号",
        //   key: "icCard",
        //   width: 120,
        // },
        // {
        //   title: "驾驶证",
        //   key: "driverCard",
        //   width: 120,
        // },
        // {
        //   title: "从业资格证",
        //   key: "certificate",
        //   width: 160,
        // },
        // {
        //   title: "发证机构",
        //   key: "certifyingAuthority",
        //   width: 120,
        // },
        // {
        //   title: "发证时间",
        //   key: "issueDate",
        //   width: 100,
        //   render: (h, params) => {
        //     let issueDate = params.row.issueDate;
        //     return (
        //       <p>{issueDate ? moment(new Date(issueDate)).format("yyyy-MM-dd") : ""}</p>
        //     );
        //   },
        // },
        // {
        //   title: "监管机构",
        //   key: "regulator",
        //   width: 120,
        // },
        // {
        //   title: "监督电话",
        //   key: "regulatoryPhone",
        //   width: 120,
        // },
        // {
        //   title: "所属区域",
        //   key: "area",
        //   width: 240,
        // },
        // {
        //   title: "联系地址",
        //   key: "address",
        //   width: 240,
        // },
        // {
        //   title: "籍贯",
        //   key: "nativeplace",
        //   width: 240,
        // },
        // {
        //   title: "过期时间",
        //   key: "expirationTime",
        //   width: 100,
        //   render: (h, params) => {
        //     let expirationTime = params.row.expirationTime;
        //     return (
        //       <p>
        //         {expirationTime
        //           ? moment(new Date(expirationTime)).format("yyyy-MM-dd")
        //           : ""}
        //       </p>
        //     );
        //   },
        // },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.car-content-header {
  text-align: end;
}
.driver-manage-wrapper {
  background-color: #eee;
  width: 100%;
  height: 100%;
}

::v-deep .el-table__body-wrapper {
  flex: 1;
}
.el-header {
  position: relative;
  background-color: #ffffff;
  color: #000000;

  box-sizing: border-box;
}
.el-main {
  padding: 0;
}
.el-pagination {
  text-align: center;
  margin: 10px 0;
}
.el-table-style {
  border: 1px solid #ddd;
}
.el-container {
  height: 100%;
  width: 100%;
  background: #fbfafa;
  padding: 12px 0;
  box-sizing: border-box;
}
.el-aside {
  background: #eceeef;
}

.el-collapse {
  border: none;
  padding: 0 10px;
  background: #ffffff;
}
</style>
